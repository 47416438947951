import React , { useEffect }from 'react'
import { Routes, Route } from 'react-router-dom'
import './App.css';
import HomePage from "./pages/HomePage";
import Layout from "./layout/Layout";
import AboutPage from "./pages/AboutPage";
import ReactGA from 'react-ga4';
import $ from 'jquery';
window.$ = $;
window.jQuery = $;

function App() {
  
  useEffect(() => {
    ReactGA.initialize('G-34XNR2PG5V'); // Remplacez par votre Measurement ID
    ReactGA.send('pageview'); // Envoie une vue de page initiale
  }, []);
  
  return (
    <Routes>
      <Route path="/" element={<Layout />} >
        <Route index element={<HomePage />} />
        <Route path="aprops" element={<AboutPage />} />
      </Route>
    </Routes>
  );
}

export default App;
